.user-profile {
  @extend .box-style;


  .up-head-w {
    background-size: cover;
    background-position: center center;
    position: relative;
    color: #fff;
    border-radius: $global-border-radius $global-border-radius 0px 0px;
    .decor {
      position: absolute;
      bottom: -1px;
      right: 0px;
      max-width: 100%;
      z-index: 3;
      .decor-path {
        fill: #fff;
      }
    }
    &:before {
      z-index: 1;
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,0.2),70%,rgba(0,0,0,0.5));
    }
    .up-social {
      position: absolute;
      top: 15px;
      right: 25px;
      z-index: 5;
      a {
        color: rgba(255,255,255,0.8);
        display: inline-block;
        font-size: 30px;
        margin-left: 10px;
        &:hover {
          color: rgba(255,255,255,1);
          text-decoration: none;
        }
      }
    }
  }
  .up-main-info {
    padding: 20% 5% 5% 5%;
    position: relative;
    z-index: 4;
    .user-avatar-w {
      margin-bottom: 1rem;
      .user-avatar {
        width: 60px;
        height: 60px;
        border-radius: 40px;
        border: 3px solid #fff;
        overflow: hidden;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.4);
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
  .up-header {
    text-shadow: 1px 1px 3px rgba(0,0,0,0.6);
    color: #fff;
    font-size: $font-size-h1 * 1.5;
    border-bottom: 1px solid rgba(255,255,255,0.4);
    padding-bottom: 10px;
    display: inline-block;
    margin-bottom: 10px;
  }
  .up-sub-header {
    text-shadow: 0px 2px 4px rgba(0,0,0,0.4);
    color: rgba(255,255,255,0.8)

  }
  .up-controls {
    padding: 20px 5%;
  }
  .btn + .btn {
    margin-left: 20px;
  }

  .up-controls + .up-contents {
    border-top: 1px solid rgba(0,0,0,0.05);
  }

  .up-contents {
    padding: 20px 5%;
    padding-top: 3rem;
  }


  // ----------------------
  // COMPACT PROFILE LAYOUT
  // ----------------------
  &.compact {
    .up-contents {
      padding-top: 0px;
    }
    .up-main-info {
      padding-top: 35%;
    }
    .up-head-w .up-social a {
      font-size: 20px;
    }
    .up-header {
      font-size: $font-size-h4;
      margin-bottom: 10px;
      padding-bottom: 5px;
    }
    .up-sub-header {
      font-size: $font-size-base * 0.9;
    }
  }
}

.user-profile + .element-wrapper {
  margin-top: 2rem;
}

.value-pair {
  display: inline-block;
  .label {
    @include text-faded();
    @include text-small-caps();
    display: inline-block;
  }
  .value {
    display: inline-block;
    @include text-small-caps();
  }
}
.value-pair + .value-pair {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid rgba(0,0,0,0.1);
}