.btn-orange{
    background-color: #f45000;
    border-color: #f45000;
    color: #fff;

    &:hover{
        background-color: #e44b00;
        border-color: #e44b00;
        color: #fff;
    }
}
