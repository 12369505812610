.question-group-form {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .05);

    &:last-child{
        border-bottom: none !important;
    }
}

.question-content {
    margin-bottom: 6px;

    p{
        margin-bottom: 0;
    }
}

.answer-group-form p {
    margin-bottom: 0;
}

.answer-item-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    label{
        padding: 0 10px;
        margin-bottom: 0;
    }

    .answer-option {
        display: inline-block;
        padding: 5px 10px;
        border: 2px solid #000000;
        color: inherit;
        text-decoration: none;

        &:hover{
            color: inherit;
            text-decoration: none;
        }

        &.selected{
            background: url("../img/cross-hand.png");
        }
    }
}

.badge-score{
    position: relative;

    span{
        font-size: 135px;
        font-weight: bolder;
        position: absolute;
        top: 14%;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        color: #b66902;
        text-shadow: 0 1px 20px #fdeee1;
    }

    &:after{
        content: "";
        background: url("../img/badge-score-line.png");
        width: 100%;
        height: 117px;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        bottom: -7px;
    }
}

.badge-score-background{
    width: 100%;
    height: 280px;

    &:before{
        content: "";
        background: url("../img/badge-score.png");
        background-repeat: no-repeat;
        background-position: top;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
    }
}
