/*
  STYLE NAVIGATION

#1. Mobile Menu
#2. Side Menu Default
#3. Side Menu Compact
#4. Top Menu Default
#5. Top Menu Image
#6. Top Menu Secondary
#7. Side Menu V2

*/


/*
  #1. Mobile Menu
*/

.menu-mobile {
    background-color: #fff;
    box-shadow: $element-box-shadow;
    position: relative;
    display: none;

    .mm-logo-buttons-w {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;

        .mm-logo {
            vertical-align: middle;
            text-align: left;
            text-decoration: none;

            img {
                width: 40px;
                display: inline-block;
            }

            span {
                display: inline-block;
                color: $body-color;
                margin-left: 1rem;
            }

            &:hover {
                text-decoration: none;
            }
        }

        .mm-buttons {
            display: flex;
        }

        .content-panel-open {
            display: none;
            font-size: 18px;
            cursor: pointer;
            margin-right: 20px;
            padding-right: 20px;
            border-right: 1px solid rgba(0, 0, 0, 0.1);
            color: $brand-primary;
        }

        .mobile-menu-trigger {
            vertical-align: middle;
            text-align: right;
            font-size: 18px;
            cursor: pointer;
            color: $brand-primary;
        }
    }

    .menu-and-user {
        display: none;
        padding-bottom: 20px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .logged-user-w {
        text-align: left;
        padding: 1rem;
        padding-left: 35px;

        .avatar-w {
            vertical-align: middle;

            img {
                width: 40px;
            }
        }

        .logged-user-info-w {
            display: inline-block;
            vertical-align: middle;
            margin-left: 15px;
        }
    }

    .mobile-menu-magic {
        background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
        border-radius: 6px;
        padding: 40px;
        text-align: center;
        margin: 10px 20px;

        &:last-child {
            margin-bottom: 0px;
        }

        h1, h2, h3, h4, h5 {
            color: #fff;
            margin-bottom: 5px;
        }

        p {
            color: rgba(255, 255, 255, 0.6);
        }

        .btn-w {
            margin-top: 15px;
        }

        .btn-white {
            border: none;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        }
    }

    ul.main-menu {
        list-style: none;
        padding: 10px 1rem;
        margin-bottom: 0px;

        > li {
            display: block;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:last-child {
                border-bottom: none;
            }

            &.has-sub-menu {
                > a {
                    &:before {
                        @include osfont();
                        content: "\e91c";
                        font-size: 7px;
                        color: rgba(0, 0, 0, 0.5);
                        transform: translateY(-50%);
                        position: absolute;
                        top: 50%;
                        right: 10px;
                    }
                }

                &.active {
                    .sub-menu {
                        display: block;
                    }
                }
            }

            > a {
                color: $body-color;
                display: flex;
                align-items: center;
                position: relative;

                &:focus {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    @media (min-width: 1025px) {
                        .icon-w {
                            transform: translateX(10px);
                        }
                        span {
                            transform: translateX(5px);
                        }
                    }
                }
            }

            .icon-w {
                color: $menu-light-icons-color;
                font-size: 27px;
                display: block;
                padding: 1rem;
                width: 80px;
                text-align: center;
                transition: all 0.2s ease;
            }

            span {
                padding: 1rem;
                display: block;
                flex: 1;
                transition: all 0.2s ease;
            }

            .icon-w + span {
                padding-left: 0px;
            }
        }
    }

    /* Styles for sub menu */
    ul.sub-menu {
        padding: 1rem 0px;
        padding-left: 55px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        display: none;

        li {
            padding: 0.4rem 10px 0.4rem 10px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:last-child {
                border-bottom: none;
            }

            a {
                // color: $body-color;
                font-size: $font-size-base * 0.9;
            }
        }
    }

    &.color-scheme-dark {
        @include gradient-y(#3D4D75, #31395B);
        @include gradient-y(#1c4cc3, #1c2e7b);
        color: rgba(255, 255, 255, 0.9);

        .side-menu-magic {
            background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
        }

        ul.sub-menu li {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            a {
                color: #fff;
            }
        }

        ul.main-menu {
            .icon-w {
                color: $menu-dark-icons-color;
            }

            > li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                > a {
                    color: #fff;

                    &:before {
                        color: #fff;
                    }
                }
            }
        }

        .sub-menu-w {
            box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
        }

        .mm-logo-buttons-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            span {
                color: #fff;
            }

            .content-panel-open {
                border-right-color: rgba(255, 255, 255, 0.1);
            }

            .content-panel-open,
            .mobile-menu-trigger {
                color: #fff;
            }
        }

        .logged-user-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            .avatar-w {
                border-color: #fff;
            }

            .logged-user-role {
                color: rgba(255, 255, 255, 0.4);
            }
        }

        .mobile-menu-magic {
            background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
        }
    }
}


/*
  #2. Side Menu Default
*/

.menu-side-w {
    background-color: $menu-side-light-bg;
    box-shadow: $element-box-shadow;
    position: relative;
    width: $menu-side-width;
    border-radius: $global-border-radius 0px 0px $global-border-radius;

    .logged-user-w {
        position: relative;

        .logged-user-i {
            display: inline-block;

            &:hover {
                cursor: pointer;

                .logged-user-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }

    .logged-user-menu {
        background: $sub-menu-dark-bg-color;
        box-shadow: 0 2px 30px 0 rgba(45, 130, 255, 0.75);
        position: absolute;
        top: 0px;
        left: -10px;
        right: -10px;
        overflow: hidden;
        padding: 1rem;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s ease;
        border-radius: $global-border-radius;

        .avatar-w {
            border-color: #fff;
        }

        .logged-user-info-w {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .logged-user-name {
                color: #fff;
            }

            .logged-user-role {
                color: rgba(255, 255, 255, 0.6);
            }
        }

        ul {
            list-style: none;
            text-align: left;
            margin: 0px;
            padding: 0px 40px;
            padding-bottom: 20px;

            li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                a {
                    display: block;
                    padding: 10px;
                    color: #fff;

                    i {
                        vertical-align: middle;
                        margin-right: 15px;
                        font-size: 20px;
                        transition: all 0.2s ease;
                        display: inline-block;
                    }

                    span {
                        vertical-align: middle;
                        font-size: $font-size-base;
                        transition: all 0.2s ease;
                        display: inline-block;
                    }

                    &:hover {
                        text-decoration: none;

                        i {
                            transform: translateX(5px);
                        }

                        span {
                            transform: translateX(8px);
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .bg-icon {
            font-size: 100px;
            color: rgba(255, 255, 255, 0.1);
            position: absolute;
            bottom: -40px;
            right: -20px;
        }
    }

    .side-menu-magic {
        // cta block
        background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
        border-radius: 6px;
        padding: 40px;
        text-align: center;
        margin: 20px;

        h1, h2, h3, h4, h5 {
            color: #fff;
            margin-bottom: 5px;
        }

        p {
            color: rgba(255, 255, 255, 0.6);
        }

        .btn-w {
            margin-top: 15px;
        }

        .btn-white {
            border: none;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        }
    }

    .logo-w {
        text-align: center;
        padding: 2rem 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        a {
            display: inline-block;
        }

        .logo {
            display: inline-block;
        }

        img {
            width: 40px;
            height: auto;
            display: inline-block;
            vertical-align: middle;
        }

        span {
            vertical-align: middle;
            display: inline-block;
            color: $headings-color;
            margin-left: 1rem;
        }
    }

    ul.main-menu {
        list-style: none;
        padding: 2rem;
        margin-bottom: 0px;

        > li {
            display: block;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:last-child {
                border-bottom: none;
            }

            &[data-active=true] {
                > a {
                    color: #3b75e3;
                }
            }

            &.has-sub-menu {
                > a {
                    &:before {
                        @include osfont();
                        content: "\e91c";
                        font-size: 7px;
                        color: rgba(0, 0, 0, 0.5);
                        transform: translateY(-50%);
                        position: absolute;
                        top: 50%;
                        right: 10px;
                    }
                }

                &.active {
                    .sub-menu {
                        display: block;
                    }
                }

                &[data-active=true] {
                    > a {
                        color: #3b75e3;
                    }
                }
            }

            > a {
                color: $body-color;
                display: flex;
                align-items: center;
                position: relative;

                &:focus {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    @media (min-width: 1025px) {
                        .icon-w {
                            transform: translateX(10px);
                        }
                        span {
                            transform: translateX(5px);
                        }
                    }
                }
            }

            .icon-w {
                color: $menu-light-icons-color;
                font-size: 27px;
                display: block;
                padding: 1rem;
                width: 80px;
                text-align: center;
                transition: all 0.2s ease;
            }

            span {
                padding: 1rem;
                display: block;
                flex: 1;
                transition: all 0.2s ease;
            }

            .icon-w + span {
                padding-left: 0px;
            }
        }
    }

    /* Styles for sub menu */
    ul.sub-menu {
        padding: 1rem 0px;
        padding-left: 55px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        display: none;
        list-style: none;

        li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:last-child {
                border-bottom: none;
            }

            &[data-active=true] {
                > a:before {
                    background-color: #047bf8;
                }
            }

            a {
                padding: 0.4rem 10px 0.4rem 10px;
                display: block;
                position: relative;
                font-size: $font-size-base * 0.9;
                transition: all 0.2s ease;

                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border: 1px solid $brand-primary;
                    position: absolute;
                    left: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                    display: block;
                    transition: all 0.2s ease;
                    border-radius: 6px;
                }

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                    transform: translateX(-5px);

                    &:before {
                        transform: translate(-5px, -50%);
                        border-radius: 6px;
                        background-color: $brand-primary;
                        border-color: $brand-primary;
                    }
                }
            }
        }
    }

    &.color-scheme-dark {
        @include gradient-y(#3D4D75, #31395B);
        @include gradient-y($menu-side-dark-bg, darken($menu-side-dark-bg, 20%));
        color: rgba(255, 255, 255, 0.9);

        .logged-user-menu {
            background-color: #5e00da;
            box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.25);
        }

        .side-menu-magic {
            background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
        }

        ul.sub-menu li {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            a {
                color: #fff;
            }
        }

        ul.main-menu {
            .icon-w {
                color: $menu-dark-icons-color;
            }

            > li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                > a {
                    color: #fff;

                    &:before {
                        color: #fff;
                    }
                }
            }
        }

        .sub-menu-w {
            box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
        }

        .logo-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            span {
                color: #fff;
            }
        }

        .logged-user-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            .avatar-w {
                border-color: #fff;
            }

            .logged-user-role {
                color: rgba(255, 255, 255, 0.4);
            }
        }
    }
}


/* Logged user box in the menu */
.logged-user-w {
    text-align: center;
    padding: 1rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    .avatar-w {
        padding: 5px;
        border: 1px solid #262626;
        border-radius: 50px;
        overflow: hidden;
        display: inline-block;

        img {
            width: 50px;
            height: auto;
            border-radius: 50px;
        }
    }

    .logged-user-name {

    }

    .logged-user-role {
        display: block;
        @include text-small-caps();
        @include text-faded();
    }
}


/*
  #3. Side Menu Compact
*/

.menu-side-compact-w {
    background-color: $menu-side-compact-light-bg;
    box-shadow: $element-box-shadow;
    position: relative;
    width: $menu-side-compact-width;
    border-radius: $global-border-radius 0px 0px $global-border-radius;

    .logo-w {
        text-align: center;
        padding: 19px 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        a {
            display: inline-block;
        }

        .logo {
            display: inline-block;
        }

        img {
            width: 40px;
            height: auto;
            display: inline-block;
            vertical-align: middle;
        }

        span {
            vertical-align: middle;
            display: block;
            color: $headings-color;
            margin-top: 10px;
        }
    }

    a {
        text-decoration: none;
    }

    .menu-and-user {
        padding-bottom: 50px;
    }

    ul.main-menu {
        list-style: none;
        padding: 0px;
        text-align: center;

        > li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            position: relative;

            > a {
                display: block;
                padding: 15px 10px;
                transition: all 0.4s ease;

                .icon-w {
                    transition: all 0.4s ease;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            &.active {
                > a {
                    background-color: $sub-menu-dark-bg-color;

                    .icon-w {
                        color: #fff;
                    }
                }

                .sub-menu-w {
                    // display: block;
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(0px) scale(1);
                }
            }
        }

        .icon-w {
            font-size: 30px;
            color: $menu-light-icons-color;
        }
    }

    .sub-menu-w {
        z-index: 9999;
        // display: none;
        visibility: hidden;
        opacity: 0;
        transform: translateX(40px) scale(0.95);
        transition: all 0.2s ease;
        position: absolute;
        background-color: $sub-menu-dark-bg-color;
        color: #fff;
        left: 100%;
        top: -100px;
        overflow: hidden;
        text-align: left;
        box-shadow: 0px 2px 40px 0px rgba(26, 138, 255, 0.5);
        padding-bottom: 20px;

        .sub-menu-title {
            font-size: 2.4rem;
            color: rgba(255, 255, 255, 0.2);
            padding: 5px 50px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            margin-bottom: 15px;
            letter-spacing: -0.5px;
            white-space: nowrap;
            overflow: hidden;
            font-family: $headings-font-family;
            font-weight: $headings-font-weight;
        }

        .sub-menu-icon {
            position: absolute;
            font-size: 120px;
            color: rgba(255, 255, 255, 0.1);
            bottom: -50px;
            right: -10px;
        }

        .sub-menu-i {
            display: flex;

            .sub-menu + .sub-menu {
                border-left: 1px solid rgba(255, 255, 255, 0.1);
            }
        }

        > ul, .sub-menu-i > ul {
            list-style: none;
            padding: 0px;
            min-width: 250px;

            > li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);

                &:last-child {
                    border-bottom: none;
                }

                > a {
                    color: #fff;
                    display: block;
                    padding: 12px 50px;
                    font-size: $font-size-base * 1.1;
                    position: relative;
                    white-space: nowrap;

                    &:before {
                        content: "";
                        display: block;
                        width: 8px;
                        height: 8px;
                        border-radius: 10px;
                        background-color: #FBB463;
                        position: absolute;
                        left: 25px;
                        top: 50%;
                        transform: translate(-10px, -50%);
                        opacity: 0;
                        transition: all 0.2s ease;
                    }

                    &:hover {
                        &:before {
                            opacity: 1;
                            transform: translate(0px, -50%);
                        }
                    }
                }
            }
        }
    }

    .logged-user-w {
        position: relative;

        .avatar-w {
            img {
                width: 40px;
            }
        }

        .logged-user-i {
            display: inline-block;

            &:hover {
                cursor: pointer;

                .logged-user-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }

        .logged-user-menu {
            background: $sub-menu-dark-bg-color;
            box-shadow: 0 2px 30px 0 rgba(45, 130, 255, 0.75);
            position: absolute;
            top: 0px;
            left: -10px;
            overflow: hidden;
            padding: 1rem;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s ease;
            text-align: left;
            border-radius: $global-border-radius;

            .logged-user-avatar-info {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 20px;
                padding: 0px 30px 20px 13px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }

            .avatar-w {
                border-color: #fff;
                vertical-align: middle;
                margin-right: 20px;
            }

            .logged-user-info-w {
                vertical-align: middle;

                .logged-user-name {
                    color: #fff;
                    white-space: nowrap;
                }

                .logged-user-role {
                    color: rgba(255, 255, 255, 0.6);
                    white-space: nowrap;
                }
            }

            ul {
                list-style: none;
                text-align: left;
                margin: 0px;
                padding: 0px;
                padding-bottom: 20px;

                li {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                    a {
                        display: block;
                        padding: 10px 20px;
                        color: #fff;

                        i {
                            vertical-align: middle;
                            margin-right: 15px;
                            font-size: 20px;
                            transition: all 0.2s ease;
                            display: inline-block;
                        }

                        span {
                            vertical-align: middle;
                            font-size: $font-size-base;
                            transition: all 0.2s ease;
                            display: inline-block;
                        }

                        &:hover {
                            text-decoration: none;

                            i {
                                transform: translateX(5px);
                            }

                            span {
                                transform: translateX(8px);
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            .bg-icon {
                font-size: 100px;
                color: rgba(255, 255, 255, 0.1);
                position: absolute;
                bottom: -40px;
                right: -20px;
            }
        }
    }


    &.color-scheme-dark {
        color: rgba(255, 255, 255, 0.9);
        @include gradient-y($menu-side-compact-dark-bg, darken($menu-side-compact-dark-bg, 20%));

        ul.main-menu {
            .icon-w {
                color: $menu-dark-icons-color;
            }

            > li {
                border-bottom-color: rgba(255, 255, 255, 0.05);
            }
        }

        .sub-menu-w {
            box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
        }

        .logo-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);
        }

        .logged-user-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            .avatar-w {
                border-color: #fff;
            }
        }
    }
}


/*
  #4. Top Menu Default
*/

.menu-top-w {
    box-shadow: $element-box-shadow;
    background-color: $menu-top-light-bg;
    border-radius: $global-border-radius $global-border-radius 0px 0px;
    position: relative;

    &.color-scheme-dark {
        background-color: $menu-top-dark-bg;

        .menu-top-i ul.main-menu > li {
            border-right-color: rgba(255, 255, 255, 0.1);
        }

        .menu-top-i ul.main-menu > li .icon-w {
            color: rgba(255, 255, 255, 0.7);
        }

        .menu-top-i ul.main-menu > li > a {
            color: #fff;
        }

        .menu-top-i ul.main-menu > li.has-sub-menu > a:before {
            color: rgba(255, 255, 255, 0.7);
        }
    }

    .menu-top-i {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .sub-menu {
            position: absolute;
            left: 0px;
            right: 0px;
            list-style: none;
            display: none;
            justify-content: center;
            flex-wrap: wrap;
            top: 100%;
            padding: 15px 20px;
            background-color: $sub-menu-dark-bg-color;
            box-shadow: 0 2px 40px 0 rgba(26, 138, 255, 0.50);

            li {
                border-right: 1px solid rgba(255, 255, 255, 0.1);

                a {
                    color: #fff;
                    display: inline-block;
                    padding: 5px 25px;
                    position: relative;

                    &:before {
                        content: "";
                        display: block;
                        width: 6px;
                        height: 6px;
                        border-radius: 10px;
                        background-color: #FBB463;
                        position: absolute;
                        left: 10px;
                        top: 50%;
                        transform: translate(-10px, -50%);
                        opacity: 0;
                        transition: all 0.2s ease;
                    }

                    &:hover {
                        text-decoration: none;

                        &:before {
                            opacity: 1;
                            transform: translate(0px, -50%);
                        }
                    }
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

        .logged-user-w {
            white-space: nowrap;
            border-bottom: none;
            padding: 0px 2rem 0px 1rem;

            .avatar-w,
            .logged-user-info-w {
                display: inline-block;
                vertical-align: middle;
            }

            .avatar-w {
                padding: 3px;

                img {
                    width: 30px;
                }
            }

            .logged-user-info-w {
                margin-left: 20px;
                text-align: left;
            }
        }

        .logo-w {
            flex: 0 0 100px;
            text-align: center;
            padding: 0rem 2rem;
            border-right: 1px solid rgba(0, 0, 0, 0.05);

            a {
                display: inline-block;
                white-space: nowrap;
            }

            .logo {
                display: inline-block;
            }

            img {
                width: 40px;
                height: auto;
                display: inline-block;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
                display: inline-block;
                color: $headings-color;
                margin-left: 1rem;
            }
        }

        ul.main-menu {
            list-style: none;
            padding: 0px 1rem;
            margin-bottom: 0px;
            display: flex;

            > li {
                display: block;
                border-right: 1px solid rgba(0, 0, 0, 0.05);

                &:last-child {
                    border-bottom: none;
                }

                &.has-sub-menu {
                    > a {
                        &:before {
                            @include osfont();
                            content: "\e91c";
                            font-size: 7px;
                            color: rgba(0, 0, 0, 0.5);
                            transform: translateY(-50%);
                            position: absolute;
                            top: 50%;
                            right: 15px;
                        }
                    }

                    &.active {
                        .sub-menu {
                            display: flex;
                        }

                        > a {
                            background-color: $sub-menu-dark-bg-color;
                            color: #fff;

                            .icon-w {
                                color: $menu-dark-icons-color;
                            }
                        }
                    }
                }

                > a {
                    color: $body-color;
                    display: flex;
                    align-items: center;
                    position: relative;

                    &:focus {
                        text-decoration: none;
                    }

                    &:hover {
                        text-decoration: none;
                        background-color: $sub-menu-dark-bg-color;
                        color: #fff;

                        .icon-w {
                            color: $menu-dark-icons-color;
                        }
                    }
                }

                .icon-w {
                    color: $menu-light-icons-color;
                    font-size: 20px;
                    display: block;
                    padding: 1rem;
                    width: 45px;
                    text-align: center;
                    transition: all 0.2s ease;
                }

                span {
                    padding: 10px;
                    padding-right: 30px;
                    display: block;
                    flex: 1;
                    transition: all 0.2s ease;
                    white-space: nowrap;
                }

                .icon-w + span {
                    padding-left: 0px;
                }
            }
        }
    }
}


/*
  #5. Top Menu Image
*/
.menu-top-image-w {
    min-height: 300px;
    background-size: cover;
    background-position: center center;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.00) 0%, rgba(95, 95, 95, 0.06) 63%, rgba(0, 0, 0, 0.73) 100%);
        z-index: 1;
    }

    .top-part-w {
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        .logo-w {
            text-align: center;
            padding: 1rem 2rem;

            a {
                display: inline-block;
            }

            .logo {
                display: inline-block;
            }

            img {
                width: 40px;
                height: auto;
                display: inline-block;
                vertical-align: middle;
            }

            span {
                vertical-align: middle;
                display: inline-block;
                color: #fff;
                margin-left: 1rem;
            }
        }

        .user-and-search {
            display: flex;
            align-items: center;
        }

        .element-search {
            position: relative;

            &:before {
                @include osfont();
                position: absolute;
                left: 15px;
                top: 48%;
                transform: translateY(-50%);
                font-size: 16px;
                content: "\e92c";
                color: rgba(0, 0, 0, 0.4);
            }

            input {
                border: none;
                box-shadow: none;
                background-color: transparent;
                border-radius: 30px;
                padding: 8px 15px 8px 40px;
                display: block;
                width: 250px;
                outline: none;
                border: none;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
                background: #fff;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }

        .logged-user-w {
            padding: 1rem 2rem;
            border-bottom: none;

            .avatar-w {
                img {
                    width: 40px;
                    display: block;
                }
            }
        }
    }

    .page-menu-header {
        position: relative;
        z-index: 2;
        font-size: $font-size-h1 * 1.5;
        color: #fff;
        padding: 2rem;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.6);
        margin: 0px
    }

    .menu-top-image-i {
        display: flex;
        position: absolute;
        z-index: 2;
        bottom: 0px;
        left: 0px;
        right: 0px;
        justify-content: space-between;
        align-items: center;
    }

    .sub-menu {
        position: absolute;
        left: 0px;
        right: 0px;
        list-style: none;
        display: none;
        justify-content: center;
        flex-wrap: wrap;
        top: 100%;
        padding: 25px 20px;
        background-color: $sub-menu-dark-bg-color;
        box-shadow: 0 2px 40px 0 rgba(26, 138, 255, 0.50);

        li {
            border-right: 1px solid rgba(255, 255, 255, 0.1);

            a {
                color: #fff;
                display: inline-block;
                padding: 5px 25px;
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    width: 6px;
                    height: 6px;
                    border-radius: 10px;
                    background-color: #FBB463;
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translate(-10px, -50%);
                    opacity: 0;
                    transition: all 0.2s ease;
                }

                &:hover {
                    text-decoration: none;

                    &:before {
                        opacity: 1;
                        transform: translate(0px, -50%);
                    }
                }
            }

            &:last-child {
                border-right: none;
            }
        }
    }


    ul.main-menu {
        list-style: none;
        padding: 0rem;
        margin-bottom: 0px;
        display: flex;

        > li {
            display: block;
            font-size: round($font-size-base * 1.2);

            &:last-child {
                border-bottom: none;
            }

            &.has-sub-menu {
                > a {
                    &:before {
                        @include osfont();
                        content: "\e91c";
                        font-size: 7px;
                        color: rgba(255, 255, 255, 0.5);
                        color: transparent;
                        transform: translateY(-50%);
                        position: absolute;
                        top: 50%;
                        right: 15px;
                    }
                }

                &.active {
                    .sub-menu {
                        display: flex;
                    }

                    > a {
                        background-color: $sub-menu-dark-bg-color;
                        color: #fff;

                        .icon-w {
                            color: $menu-dark-icons-color;
                        }
                    }
                }
            }

            > a {
                color: #fff;
                display: flex;
                align-items: center;
                position: relative;

                &:after {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0px;
                    background-color: rgba(255, 255, 255, 0.3);
                    content: "";
                    height: 16px;
                    width: 1px;
                }

                &:focus {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    background-color: $sub-menu-dark-bg-color;
                    color: #fff;

                    &:after {
                        background-color: transparent;
                    }

                    .icon-w {
                        color: $menu-dark-icons-color;
                    }
                }
            }

            .icon-w {
                color: #fff;
                font-size: 22px;
                display: block;
                padding: 15px 15px 15px 30px;
                text-align: center;
                transition: all 0.2s ease;
            }

            span {
                padding: 10px;
                padding-right: 30px;
                display: block;
                flex: 1;
                transition: all 0.2s ease;
                white-space: nowrap;
            }

            .icon-w + span {
                padding-left: 0px;
            }
        }
    }
}


/*
  #6. Top Menu Secondary
*/
.all-wrapper > .top-menu-secondary {
    border-radius: $global-border-radius $global-border-radius 0px 0px;
}

.top-menu-secondary {
    background-color: $top-menu-secondary-light-bg;
    box-shadow: $element-box-shadow;
    padding: 0px 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-radius: 0px $global-border-radius 0px 0px;

    > ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        text-transform: uppercase;
        color: $body-color;
        font-weight: $font-weight-bold;
        letter-spacing: 2px;
        font-size: $font-size-base * 1.1;

        li {
            display: inline-block;

            a {
                display: inline-block;
                padding: 20px 25px;
                color: rgba(0, 0, 0, 0.25);
                position: relative;
                transition: all 0.2s ease;

                &:after {
                    content: "";
                    background-color: $brand-primary;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0px;
                    height: 5px;
                    transition: all 0.2s ease;
                }
            }

            &.active, &:hover {
                a {
                    color: $body-color;
                    text-decoration: none;

                    &:after {
                        width: 100%;
                    }
                }
            }
        }
    }

    .fancy-selector-w {
        margin-left: auto;
        align-self: stretch;
    }

    .logo-w.menu-size + .fancy-selector-w {
        margin-left: 0px;
    }

    .logo-w {
        text-align: left;

        a {
            display: inline-block;
        }

        img {
            height: 35px;
            width: auto;
            display: inline-block;
            vertical-align: middle;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }

        img + span {
            margin-left: 20px;
            color: $body-color;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: $font-size-base * 0.9;
            font-weight: $font-weight-bold;
        }

        &.menu-size {
            width: $menu-side-width - 20px;
        }
    }


    .top-menu-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: auto;
        padding: 7px 0px;

        .element-search {
            position: relative;
            margin: 0px 1rem;

            &:before {
                @include osfont();
                position: absolute;
                left: 15px;
                top: 48%;
                transform: translateY(-50%);
                font-size: 16px;
                content: "\e92c";
                color: rgba(0, 0, 0, 0.4);
            }

            input {
                border: none;
                box-shadow: none;
                background-color: transparent;
                border-radius: 30px;
                padding: 8px 15px 8px 40px;
                display: block;
                width: 220px;
                outline: none;
                border: none;
                box-shadow: none;
                background: #eee;

                &::placeholder {
                    color: rgba(0, 0, 0, 0.5);
                }
            }
        }
    }

    .messages-notifications {
        margin: 0px 1rem;
        position: relative;
        font-size: 24px;
        color: $brand-primary;

        .new-messages-count {
            background-color: $brand-danger;
            color: #fff;
            border-radius: 6px;
            font-weight: $font-weight-bold;
            position: absolute;
            top: -5px;
            right: -12px;
            padding: 4px 4px;
            vertical-align: middle;
            font-size: $font-size-base * 0.8;
            line-height: 1;
        }
    }

    .top-icon {
        margin: 0px 1rem;
        color: $brand-primary;
        font-size: 26px;
    }

    .logged-user-w {
        position: relative;
        padding: 0px 1rem;
        border-bottom: none;

        .avatar-w {
            padding: 0px;
            border: none;

            img {
                width: 40px;
            }
        }

        .logged-user-i {
            display: inline-block;

            &:hover {
                cursor: pointer;

                .logged-user-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }

        .logged-user-menu {
            background: $sub-menu-dark-bg-color;
            box-shadow: 0 2px 30px 0 rgba(45, 130, 255, 0.75);
            position: absolute;
            top: -10px;
            right: -20px;
            overflow: hidden;
            padding: 1rem;
            z-index: 999;
            visibility: hidden;
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.3s ease;
            text-align: left;
            border-radius: $global-border-radius;

            .logged-user-avatar-info {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 10px;
                padding: 0px 30px 10px 13px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }

            .avatar-w {
                border-color: #fff;
                vertical-align: middle;
                margin-right: 20px;
            }

            .logged-user-info-w {
                vertical-align: middle;

                .logged-user-name {
                    color: #fff;
                    white-space: nowrap;
                }

                .logged-user-role {
                    color: rgba(255, 255, 255, 0.6);
                    white-space: nowrap;
                }
            }

            ul {
                list-style: none;
                text-align: left;
                margin: 0px;
                padding: 0px;
                padding-bottom: 0px;

                li {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                    a {
                        display: block;
                        padding: 10px 20px;
                        color: #fff;

                        i {
                            vertical-align: middle;
                            margin-right: 15px;
                            font-size: 20px;
                            transition: all 0.2s ease;
                            display: inline-block;
                        }

                        span {
                            vertical-align: middle;
                            font-size: $font-size-base;
                            transition: all 0.2s ease;
                            display: inline-block;
                        }

                        &:hover {
                            text-decoration: none;

                            i {
                                transform: translateX(5px);
                            }

                            span {
                                transform: translateX(8px);
                            }
                        }
                    }

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            .bg-icon {
                font-size: 100px;
                color: rgba(255, 255, 255, 0.1);
                position: absolute;
                bottom: -40px;
                right: -20px;
            }
        }
    }

    &.color-scheme-dark {
        background-color: $top-menu-secondary-dark-bg;

        .top-menu-controls .element-search input {
            background-color: rgba(0, 0, 0, 0.2);
            color: #fff;

            &::placeholder {
                color: rgba(255, 255, 255, 0.3);
            }
        }

        .top-menu-controls .element-search:before {
            color: rgba(255, 255, 255, 0.7);
        }

        .messages-notifications,
        .top-icon {
            color: #fff;
        }

        > ul {
            li {
                a {
                    color: rgba(255, 255, 255, 0.4);

                    &:after {
                        background-color: $highlight-accent;
                    }
                }

                &.active, &:hover {
                    a {
                        color: #fff;
                    }
                }
            }
        }

        .logo-w {
            img + span {
                color: #fff;
            }
        }

        .fancy-selector-w .fancy-selector-current .fs-selector-trigger {
            background-color: lighten($fancy-selector-bg-dark, 5%);
        }
    }
}


/*
  #7. Side Menu V2
*/

.menu-side-v2-w {
    position: relative;
    width: $menu-side-width;
    border-radius: $global-border-radius 0px 0px $global-border-radius;
    display: table-cell;
    border-right: 1px solid rgba(0, 0, 0, 0.05);

    .logged-user-w {
        position: relative;

        .logged-user-i {
            display: inline-block;

            &:hover {
                cursor: pointer;

                .logged-user-menu {
                    visibility: visible;
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }

    .logged-user-menu {
        background: $sub-menu-dark-bg-color;
        box-shadow: 0 2px 30px 0 rgba(45, 130, 255, 0.75);
        position: absolute;
        top: 0px;
        left: -10px;
        right: -10px;
        overflow: hidden;
        padding: 1rem;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        transform: translateY(20px);
        transition: all 0.3s ease;
        border-radius: $global-border-radius;

        .avatar-w {
            border-color: #fff;
        }

        .logged-user-info-w {
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            .logged-user-name {
                color: #fff;
            }

            .logged-user-role {
                color: rgba(255, 255, 255, 0.6);
            }
        }

        ul {
            list-style: none;
            text-align: left;
            margin: 0px;
            padding: 0px 40px;
            padding-bottom: 20px;

            li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                a {
                    display: block;
                    padding: 10px;
                    color: #fff;

                    i {
                        vertical-align: middle;
                        margin-right: 15px;
                        font-size: 20px;
                        transition: all 0.2s ease;
                        display: inline-block;
                    }

                    span {
                        vertical-align: middle;
                        font-size: $font-size-base;
                        transition: all 0.2s ease;
                        display: inline-block;
                    }

                    &:hover {
                        text-decoration: none;

                        i {
                            transform: translateX(5px);
                        }

                        span {
                            transform: translateX(8px);
                        }
                    }
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .bg-icon {
            font-size: 100px;
            color: rgba(255, 255, 255, 0.1);
            position: absolute;
            bottom: -40px;
            right: -20px;
        }
    }

    .side-menu-magic {
        // cta block
        background-image: linear-gradient(-154deg, #1643A3 8%, #2E1170 90%);
        border-radius: 6px;
        padding: 40px;
        text-align: center;
        margin: 20px;

        h1, h2, h3, h4, h5 {
            color: #fff;
            margin-bottom: 5px;
        }

        p {
            color: rgba(255, 255, 255, 0.6);
        }

        .btn-w {
            margin-top: 15px;
        }

        .btn-white {
            border: none;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
        }
    }

    .logo-w {
        text-align: center;
        padding: 2rem 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);

        a {
            display: inline-block;
        }

        .logo {
            display: inline-block;
        }

        img {
            width: 40px;
            height: auto;
            display: inline-block;
            vertical-align: middle;
        }

        span {
            vertical-align: middle;
            display: inline-block;
            color: $headings-color;
            margin-left: 1rem;
        }
    }

    ul.main-menu {
        list-style: none;
        padding: 2rem;
        margin-bottom: 0px;

        > li {
            display: block;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:last-child {
                border-bottom: none;
            }

            &.has-sub-menu {
                > a {
                    &:before {
                        @include osfont();
                        content: "\e91c";
                        font-size: 7px;
                        color: rgba(0, 0, 0, 0.5);
                        transform: translateY(-50%);
                        position: absolute;
                        top: 50%;
                        right: 10px;
                    }
                }

                &.active {
                    .sub-menu {
                        display: block;
                    }
                }
            }

            &.menu-sub-header {
                text-transform: uppercase;
                color: #448eef;
                font-size: $font-size-base * 0.8;
                letter-spacing: 1px;
                padding-top: 20px;
                padding-bottom: 0px;
                border-bottom: none;
            }

            > a {
                color: $body-color;
                display: flex;
                align-items: center;
                position: relative;
                font-size: $font-size-base * 1.1;

                &:focus {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    @media (min-width: 1025px) {
                        .icon-w {
                            transform: translateX(10px);
                        }
                        span {
                            transform: translateX(5px);
                        }
                    }
                }
            }

            .icon-w {
                color: $menu-light-icons-color;
                font-size: 27px;
                display: block;
                padding: 1rem;
                width: 80px;
                text-align: center;
                transition: all 0.2s ease;
            }

            span {
                padding: 1rem;
                display: block;
                flex: 1;
                transition: all 0.2s ease;
            }

            .icon-w + span {
                padding-left: 0px;
            }
        }
    }

    /* Styles for sub menu */
    ul.sub-menu {
        padding: 1rem 0px;
        padding-left: 55px;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
        display: none;
        list-style: none;

        li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);

            &:last-child {
                border-bottom: none;
            }

            a {
                padding: 0.4rem 10px 0.4rem 10px;
                display: block;
                position: relative;
                font-size: $font-size-base * 0.9;
                transition: all 0.2s ease;

                &:before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    border: 1px solid $brand-primary;
                    position: absolute;
                    left: -10px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #fff;
                    display: block;
                    transition: all 0.2s ease;
                    border-radius: 6px;
                }

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                    transform: translateX(-5px);

                    &:before {
                        transform: translate(-5px, -50%);
                        border-radius: 6px;
                        background-color: $brand-primary;
                        border-color: $brand-primary;
                    }
                }
            }
        }
    }

    &.color-scheme-dark {
        @include gradient-y(#3D4D75, #31395B);
        @include gradient-y($menu-side-dark-bg, darken($menu-side-dark-bg, 20%));
        color: rgba(255, 255, 255, 0.9);

        .logged-user-menu {
            background-color: #5e00da;
            box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.25);
        }

        .side-menu-magic {
            background-image: linear-gradient(-154deg, #6d16a3 8%, #5211e6 90%);
        }

        ul.sub-menu li {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            a {
                color: #fff;
            }
        }

        ul.main-menu {
            .icon-w {
                color: $menu-dark-icons-color;
            }

            > li {
                border-bottom: 1px solid rgba(255, 255, 255, 0.05);

                > a {
                    color: #fff;

                    &:before {
                        color: #fff;
                    }
                }
            }
        }

        .sub-menu-w {
            box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.2);
        }

        .logo-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            span {
                color: #fff;
            }
        }

        .logged-user-w {
            border-bottom-color: rgba(255, 255, 255, 0.05);

            .avatar-w {
                border-color: #fff;
            }

            .logged-user-role {
                color: rgba(255, 255, 255, 0.4);
            }
        }
    }


    // Flying sub menu

    &.flying-menu {
        .main-menu {
            > li.has-sub-menu {
                position: relative;

                > a:before {
                    transition: all 0.2s ease;
                }

                &.active {
                    > a:before {
                        transform: translateY(-50%) rotate(-90deg);
                    }

                    .sub-menu-w {
                        visibility: visible;
                        opacity: 1;
                        transform: translate(100%, -50%) scale(1);
                    }
                }
            }
        }

        .sub-menu-w {
            position: absolute;
            background-color: #fff;
            padding: 10px 30px 20px;
            border-radius: $global-border-radius;
            right: 0px;
            top: 50%;
            transform: translate(110%, -50%) scale(0.8);
            z-index: 10000;
            box-shadow: 0px 15px 50px rgba(0, 0, 0, 0.1);
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease;
        }

        .sub-menu-header {
            font-size: $font-size-base * 1.5;
            font-weight: $font-weight-bold;
            color: $brand-primary;
            text-align: center;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-bottom: 10px;
            margin-bottom: 10px;
        }

        .sub-menu-i {
            display: flex;
        }

        ul.sub-menu {
            display: block;
            padding-left: 0px;
            border: none;
            flex: 0 0 200px;
            padding: 0px 10px;

            li {
                min-width: 200px;

                a {
                    color: $body-color;
                    white-space: nowrap;
                    font-size: $font-size-base * 1;
                    padding: 10px 20px;

                    &:before {
                        opacity: 0;
                        transform: translate(10px, -50%);
                        background-color: $brand-primary;
                    }

                    &:hover {
                        color: $brand-primary;

                        &:before {
                            opacity: 1;
                            transform: translate(20px, -50%);
                        }
                    }
                }

                .badge {
                    font-size: $font-size-base * 0.8;
                    padding: 2px 5px;
                    margin-left: 5px;
                }
            }

            & + ul.sub-menu {
                border-left: 1px solid rgba(0, 0, 0, 0.05);
            }
        }
    }
}
