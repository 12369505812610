.line {
    margin: 5px 0;
    display: block;
    width: 100%;
    text-align: center;
    position: relative;

    &:before {
        content: "";
        width: 100%;
        border: 1px solid #f1f1f1;
        top: 50%;
        left: 0;
        position: absolute;
        z-index: 1;
    }

    span {
        padding: 0 4px;
        position: relative;
        background-color: #fff;
        z-index: 2;
        color: #bcbcbc;
    }
}

.paid-stamp {

    &:before {
        content: "";
        background: url('../img/paid-stamp.png');
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        opacity: 0.1;
    }

}

.rejected-stamp {

    &:before {
        content: "";
        background: url('../img/rejected-stamp.png');
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        opacity: 0.1;
    }

}
