
.fancy-selector-w {
  position: relative;


  // CURRENT SELECTOR 

  .fancy-selector-current {
    position: relative;
    z-index: 9999;
    height: 100%;
  }

  .fancy-selector-current, .fancy-selector-option {
    display: flex;
    background-color: $fancy-selector-bg-dark;
    color: #fff;
    text-align: left;
    align-items: center;
    justify-content: space-between;
    .fs-img {
      padding: 10px 15px;
      vertical-align: middle;
      img {
        display: inline-block;
        vertical-align: middle;
        height: 30px;
        border-radius: $global-border-radius;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.1);
      }
    }
    .fs-main-info {
      padding: 10px;
      padding-right: 20px;
      .fs-name {
        font-size: $font-size-base * 1.1;
        font-weight: $font-weight-bold;
        line-height: 1;
        letter-spacing: 1px;
        margin-bottom: 5px;
      }
      .fs-sub {
        color: rgba(255,255,255,0.6);
        letter-spacing: 0.5px;
        font-size: $font-size-base * 0.8;
        span {}
        strong {
          color: #F6DB77;
          margin-left: 5px;
        }
      }
    }
    .fs-extra-info {
      padding: 10px 20px;
      border-left: 1px solid rgba(255,255,255,0.05);

      text-align: center;
      color: rgba(255,255,255,0.6);
      strong {
        font-size: $font-size-base * 1.1;
        margin-bottom: 5px;
        font-weight: $font-weight-bold;
        display: block;
        letter-spacing: 1px;
        line-height: 1;
      }
      span {
        text-transform: uppercase;
        color: rgba(255,255,255,0.4);
        font-size: $font-size-base * 0.6;
        letter-spacing: 2px;
        line-height: 1;
        display: block;
      }
    }
    .fs-selector-trigger {
      background-color: darken($fancy-selector-bg-dark, 10%);
      padding: 10px 10px;
      font-size: 16px;
      color: rgba(255,255,255,0.7);
      align-self: stretch;
      position: relative;
      width: 60px;
      cursor: pointer;
      i {
        vertical-align: middle;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: all 0.2s ease;
      }
      &:hover {
        background-color: darken($fancy-selector-bg-dark, 20%);
        color: #fff;
        i {
          transform: translate(-50%,-40%);
        }
      }
    }
  }

  // OPTIONS
  .fancy-selector-options {
    background-color: darken($fancy-selector-bg-dark, 5%);
    position: absolute;
    left: 0px;
    min-width: 100%;
    padding: 15px 0px;
    padding-top: 25px;
    z-index: 9998;
    border-radius: 0px 0px $global-border-radius $global-border-radius;
    visibility: hidden;
    transform: translateY(-30px) scale(1);
    transition: all 0.2s ease;
    opacity: 0;

    .fancy-selector-actions {
      padding: 15px 15px 0px 15px;
    }

    // SINGLE OPTION
    .fancy-selector-option {
      padding: 8px 20px;
      border-bottom: 1px solid rgba(255,255,255,0.08);
      color: rgba(255,255,255,0.7);
      position: relative;
      background-color: transparent;
      cursor: pointer;
      border-radius: 0px;
      .fs-extra-info {
        padding-right: 10px;
      }
      .fs-img {
        padding-left: 20px;
        img {
          height: 37px;
        }
      }
      .fs-main-info {
        flex: 1;
      }
      &.active {
        color: rgba(255,255,255,1);
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background-color: #ffb900;
          position: absolute;
          top: 50%;
          left: 15px;
          border-radius: $global-border-radius;
          transform: translateY(-50%);
        }
      }
      &:hover {
        background-color: rgba(0,0,0,0.05);
        color: #fff;
      }
    }
  }
  &.opened {
    .fancy-selector-current {
    }
    .fancy-selector-options {
      visibility: visible;
      transform: translateY(-5px) scale(1);
      opacity: 1;
    }
    .fs-selector-trigger {
      i {
        transform: translate(-50%,-50%) rotate(180deg);
      }
      &:hover {
        i {
          transform: translate(-50%,-60%) rotate(180deg);
        }
      }
    }
  }
}