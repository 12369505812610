button, input, optgroup, select, textarea {
  font-family: $font-family-alt;
  font-weight: $font-alt-weight-normal;
}

.form-control.rounded {
  border-radius: 30px;
}
select.form-control.rounded {
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 40px;
  padding-left: 15px;
  background-position: right 5px top 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
}

.form-text {
  font-size: $font-size-base * 0.9;
}

.has-danger .form-control-feedback.text-muted {
  color: $brand-danger!important;
}

.has-danger .form-control{
    border-width: 3px;
}

.form-control {
  font-family: $font-family-base;
  &.bright {
    border-color: #334652;
  }
  &[type="checkbox"] {
    width: auto;
    display: inline-block;
  }
}

.form-group {

  .date-input {
    position: relative;
    &:before {
      content: "\e972";
      @include osfont();
      font-size: 15px;
      color: $brand-primary;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
    .form-control {
      padding-left: 35px;
    }
  }
}

.form-control::placeholder {
  @include text-faded();
}

.form-check-input {
  margin-right: 0.5rem;
}


.form-buttons-w {
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0,0,0,0.1);

  &.compact {
    margin-top: 0.5rem;
  }

  .btn + .btn {
    margin-left: 10px;
  }
}

label {
  &.bigger {
    font-size: $font-size-base * 1.2;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  &.lighter {
    color: $color-text-faded;
  }
}


fieldset {
  margin-top: 2rem;
}

legend {
  font-size: $font-size-base * 1.1;
  display: block;
  margin-bottom: 1.5rem;
  position: relative;
  color: $brand-primary;
  span {
    padding: 0px 0.5rem 0 0;
    background-color: #fff;
    display: inline-block;
    z-index: 2;
    position: relative;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    height: 1px;
    top: 50%;
    background-color: rgba(0,0,0,0.1);
    z-index: 1;
  }
}


.form-header {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  display: block;
}

.form-desc {
  color: #999;
  margin-bottom: 1.5rem;
  font-weight: $font-weight-light;
  font-size: $font-size-base;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0,0,0,0.05);
  display: block;
}



