/* 

Contents: 

#1.  Screen size <= 1650px
#2.  Screen size <= 1550px
#3.  Screen size <= 1350px
#4.  Screen size between 1100px - 1350px
#5.  Screen size <= 1400px
#6.  Screen size <= 1150px
#7.  Screen size <= 1250px
#8.  Tablets and phones  (< 1024px)
#9.  Tablets (768px - 1024px)
#10. Phones & Vertical Tablets (<= 768px)
#11. Phones (< 767px)


*/





/* #1.  Screen size <= 1650px */
@media (max-width: 1650px){
  body,
  body.auth-wrapper .all-wrapper {
    padding: 40px;
  }
  .content-panel {
    padding: 2rem;  
    width: 350px;
  }
  .content-box {
    padding: 2rem;
  }
  .all-wrapper {
    max-width: 100%;
  }
}











/* #2.  Screen size <= 1550px */
@media (max-width: 1550px){
  body,
  body.auth-wrapper .all-wrapper {
    padding: 20px;
  }
  .menu-side-w ul.main-menu {
    padding: 1.5rem 1rem;
  }
  .menu-side-w ul.main-menu > li .icon-w {
    padding-left: 1rem;
    width: 75px;
  }
  .menu-side-w ul.sub-menu {
    padding-left: 45px;
  }
  .menu-side-w {
    width: 260px;
    .logged-user-menu {
      ul {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}












/* #3.  Screen size <= 1350px */
@media (max-width: 1350px){
  .top-menu-secondary {
    .fs-extra-info {
      display: none;
    }
  }
  .menu-side-w .logo-w {
    padding: 1.5rem 1rem;
  }
  .menu-top-w .menu-top-i .logo-w {
    padding: 0rem 1rem;
    width: 50px;
  }
  .menu-top-w .menu-top-i ul.main-menu {
    padding-left: 0px;
  }
}












/* #4.  Screen size between 1100px - 1350px */
@media (min-width: 1100px) and (max-width: 1350px){
  .content-panel {
    width: 300px;
    padding: 1.5rem;
  }
  .padded {
    padding: 0.5rem 1rem;
  }
  .content-w {
  }
  .content-box {
    padding: 1.5rem;
  }
  .activity-boxes-w {
    .activity-avatar {
      margin-right: 1rem;
      width: 40px;
    }
    .activity-time {
      flex: 0 0 90px;
    }
    .activity-box-w:before {
      left: 65px;
    }
    .activity-box-w:after {
      left: 70px;
    }
  }
  .profile-tile {
    .profile-tile-box {
      width: 95px;
    }
    .pt-avatar-w img {
      width: 50px;
    }
    .profile-tile-meta {
      padding-left: 15px;
    }
  }
  .content-i {
  }
}













/* #5.  Screen size <= 1400px */
@media (max-width: 1400px){
  .menu-side-w {
    width: 230px;
    .side-menu-magic {
      padding: 30px 20px;
    }
    .logged-user-menu {
      ul {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .menu-top-image-w ul.main-menu > li .icon-w {
    padding-left: 20px;
  }
  .menu-top-image-w ul.main-menu > li span {
    padding-right: 20px;
  }
  .full-chat-w .chat-content-w .chat-message .chat-message-content {
    max-width: 320px;
  }
}












/* #6.  Screen size <= 1150px */
@media (max-width: 1150px){
  .all-wrapper {
  }
  .ae-content {
    padding: 0px;
  }
  .aec-full-message-w {
    margin-bottom: 0px;
  }
  .ae-content-w {
    background-image: none;
    background-color: #fff;
  }
  .aec-full-message-w .more-messages {
    top: -32px;
    background-color: #fff;
    padding: 3px 5px;
    border-radius: 4px;
    color: $brand-primary;
  }
  .ae-list-w {
    flex: 0 0 300px;
  }
  .ae-list-w .ae-list .aei-image {
    display: none;
  }
  .layout-w {
    transition: all 0.6s ease;
  }
  .content-box {
    &:after {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: rgba(4,36,113,0.60);
      content: "";
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      transition: all 0.4s ease;
    }
  }
  .content-panel {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-color: #fff;
    background-image: linear-gradient(-90deg, #EFF2F9 8%, #fff 90%);
    z-index: 4;
    border: none;
    transform: translateX(100%);
    transition: all 0.6s ease;
    visibility: hidden;
    z-index: 11;
    .content-panel-close {
      display: block;
    }
  }
  .content-panel-toggler {
    display: block;
  }
  .content-i, .with-side-panel .content-i {
    display: block;
    .content-box {
      display: block;
    }
  }
  .content-panel-active {
    .content-panel {
      transform: translateX(80px);
      visibility: visible;
      box-shadow: 0 2px 80px 0 rgba(0,0,0,0.4);
    }
    .layout-w {
      transform: translateX(-80px);
    }
    .content-box:after {
      opacity: 1;
      visibility: visible;
    }
  }
}











/* #7.  Screen size <= 1250px */
@media (max-width: 1250px){
  .element-box, .invoice-w, .big-error-w {
    padding: 1rem 1.5rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  body,
  body.auth-wrapper .all-wrapper {
    padding: 0px;
  }
  .content-w,
  .menu-side-w,
  .all-wrapper {
    border-radius: 0px!important;
    overflow: hidden;
  }
  .menu-side-w .logged-user-menu {
    left: 0px;
    right: 0px;
  }
}













/* #8.  Tablets and phones  (< 1024px) */
@media (max-width: 1024px){
  .invoice-w {
    padding: 50px;
  }
  .ae-list-w {
    flex: 0 0 230px;
  }
  .ae-list-w .ae-list .ae-item {
    padding: 15px;
  }
  .table th, .table td {
    padding: 0.7rem 0.5rem;
  }
  .content-w {
    border-radius: 0px!important;
  }

  .desktop-menu {
    display: none;
  }
}













/* #9.  Tablets (768px - 1024px) */
@media (min-width: 768px) and (max-width: 1024px){

  // Top Secondary Menu

  .top-menu-secondary {
    .logo-w {
      display: none;
    }
    .fs-extra-info {
      display: none;
    }
  }


  // Element Wrapper
  .element-wrapper {
    padding-bottom: 2rem;
  }


  // Element Balances
  .element-balances {
    justify-content: space-between;
  }


  // FULL CHAT
  .full-chat-w {
    .full-chat-right {
      display: none;
    }
    .chat-content-w .chat-content {
      padding: 30px;
    }
  }

  .top-menu-secondary {
    overflow: scroll;
    padding: 0px;
    > ul {
      white-space: nowrap;
      li a {
        padding: 15px 10px;
        margin: 0px 10px;
      }
    }
    &.with-overflow {
      overflow: visible;
    }
  }
  .nav.upper.nav-tabs .nav-link {
    margin-right: 0.7rem;
    padding-bottom: 10px;
    font-size: $font-size-base * 0.8;
  }

  .display-type {
    content: "tablet";
  }
  .content-box {
    padding: 1.5rem;
  }
  .padded {
    padding: 0.3rem 0.8rem;
  }
  .element-box, .invoice-w, .big-error-w {
    padding: 1rem 1rem;
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .layout-w {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .content-w {
    display: table-cell;
  }
  .menu-mobile {
    width: 92px;
    display: table-cell;
    .menu-and-user {
      display: block;
    }
    ul.sub-menu {
      padding: 1rem 1.5rem;
    }
    .mobile-menu-magic {
      display: none;
    }
    .logged-user-w {
      padding: 1rem;
      text-align: center;
      .avatar-w img {
        width: 35px;
      }
      .logged-user-info-w {
        display: none;
      }
    }
    ul.main-menu {
      padding: 1rem 0.5rem;
    }
    .mm-logo-buttons-w {
      text-align: center;
      justify-content: center;
      .mm-buttons {
        display: none;
      }
      .mm-logo {
        display: block;
        text-align: center;
        span {
          display: none;
        }
      }
    }
    &.color-scheme-dark {
      ul.main-menu > li > a > span {
        background-color: #fff;
        color: #111;
      }
      ul.sub-menu {
        background-image: linear-gradient(to bottom, #1c4cc3 0%, #1c2e7b 100%);
        &:before {
          border-right-color: #203c9b;
        }
      }
    }
  }

  .menu-mobile ul.main-menu > li > a > span {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0,0,0,0.9);
    border-radius: $global-border-radius;
    color: #fff;
    font-size: $font-size-base * 0.9;
    padding: 1px 5px!important;
    display: none;
    white-space: nowrap;
  }
  .menu-mobile {
    ul.main-menu li {
      position: relative;
    }
    .sub-menu {
      position: absolute;
      top: 50%;
      left: 100px;
      background-color: #fff;
      min-width: 200px;
      z-index: 999;
      list-style: none;
      padding: 1rem 1.5rem;
      box-shadow: 0px 5px 30px rgba(0,0,0,0.1);
      border-radius: $global-border-radius;
      transform: translateY(-50%);
      &:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #fff;
        border-width: 10px;
        margin-top: -10px;
      }
    }
  }

  .menu-mobile .main-menu {
    li > a:hover > span {
      display: block;
    }
  }
  .menu-mobile .has-sub-menu.active {
    .icon-w {
      color: $body-color;
    }
    .sub-menu {
      display: block;
    }
  }

  // USER PROFILE
  .user-profile {
    .up-header {
      font-size: $font-size-h1;
    }
    .up-sub-header {
      font-size: $font-size-h6;
    }
  }

  // TODO APP
  .todo-app-w {
    .todo-sidebar {
      padding: 30px;
      flex: 0 0 280px;
      .todo-sidebar-section {
        padding: 10px;
      }
    }
    .todo-content {
      padding: 30px;
    }
  }
}













/* #10. Phones & Vertical Tablets (<= 768px) */
@media (max-width: 768px){
  // TODO APP
  .todo-app-w {
    .todo-sidebar {
      padding: 30px 20px 20px 45px;
      .todo-sidebar-section {
        padding: 10px 0px;
      }
      .todo-sidebar-section-toggle {
        left: -22px;
      }
      .todo-sidebar-section-header {
        font-size: $font-size-h5;
      }
      .todo-sidebar-section-sub-section, ul.projects-list {
        padding-left: 10px;
      }
    }
    .todo-content {
      padding: 30px;
      .all-tasks-w {
        padding-left: 5px;
      }
      .tasks-header-w {
        padding-left: 20px;
        .tasks-header-toggler {
          left: -5px;
        }
      }
      .todo-content-header {
        font-size: $font-size-h5;
      }
      .all-tasks-w {
        padding-top: 0px;
        padding-right: 0px;
      }
      .tasks-list li.draggable-task {
        padding-right: 10px;
      }
    }
  }
}




/* #11. Phones (< 767px) */
@media (max-width: 767px){

  .hidden-mobile {
    display: none!important;
  }

  // Secondary Top Menu
  
  .top-menu-secondary {
    .logo-w {
      display: none;
    }
    &.with-overflow {
      flex-direction: column-reverse;
      .top-menu-controls {
        display: none;
      }
    }
    .fs-extra-info {
      display: none;
    }
  }

  // Element Balances
  .element-balances {
    flex-wrap: wrap;
    .balance {
      border: none!important;
    }
  }

  .cell-with-media {
    text-align: center;
    img {
      margin-bottom: 5px;
      & + span {
        margin-left: 0px!important;
      }
    }
  }

  .element-wrapper {
    .element-actions {
      float: none;
      margin-bottom: 20px;
    }
  }


  // TODO APP
  .todo-app-w {
    display: block;
    .todo-sidebar {
      padding: 30px 15px 20px 40px;
      flex: 1;
      .todo-sidebar-section {
        padding: 10px 0px;
      }
      .todo-sidebar-section-toggle {
        left: -22px;
      }
      .todo-sidebar-section-header {
        font-size: $font-size-h5;
      }
      .todo-sidebar-section-sub-section, ul.projects-list {
        padding-left: 10px;
      }
    }
    .todo-content {
      padding: 30px 20px;
      .todo-content-header {
        font-size: $font-size-h5;
      }
      .all-tasks-w {
        padding-top: 0px;
        padding-right: 0px;
      }
      .tasks-list li.draggable-task {
        padding-right: 10px;
      }
    }
  }


  // Chat
  .full-chat-w .full-chat-i {
    display: block;
  }

  // Projects
  .projects-list .project-head {
    padding: 20px 30px;
    display: block;
    text-align: center;
    .project-users {
      text-align: center;
      margin-top: 2rem;
    }
  }
  .projects-list .project-info {
    padding: 20px 30px;
    .el-tablo {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }
  .display-type {
    content: "phone";
  }
  .ae-list-w {
    flex: 1 1 230px;
  }
  .ae-content-w {
    display: none;
  }
  .top-menu-secondary {
    overflow: scroll;
    padding: 0px;
    > ul {
      white-space: nowrap;
      li a {
        padding: 15px 10px;
        margin: 0px 10px;
      }
    }
    &.with-overflow {
      overflow: visible;
    }
  }
  .nav.upper.nav-tabs .nav-link {
    margin-right: 0.7rem;
    padding-bottom: 10px;
    font-size: $font-size-base * 0.8;
  }
  .app-email-w {
    &.forse-show-content {
      .ae-content-w {
        display: block;
      }
      .ae-list-w {
        display: none;
      }
    }
  }
  .aec-full-message-w .message-content {
    padding: 15px;
  }
  .aec-full-message-w .message-attachments {
    text-align: center;
  }
  .aec-full-message-w .message-head .user-w .user-role span {
    margin-left: 0;
  }
  .aec-reply {
    padding: 15px;
    .reply-header {
      padding-bottom: 15px;
      padding-top: 15px;
      text-align: center;
    }
  }
  .aec-reply .buttons-w {
    display: block;
    text-align: center;
    .btn {
      margin-bottom: 10px;
    }
  }
  .floated-chat-btn i + span {
    display: none;
  }
  .floated-chat-btn {
    padding: 10px 12px;
  }
  .padded {
    padding: 1rem;
  }

  // USER PROFILE
  .user-profile {
    .up-head-w .up-social {
      top: 5px;
      right: 10px;
      a {
        font-size: 24px;
      }
    }
    .up-main-info {
      padding-bottom: 10%;
    }
    .up-header {
      font-size: $font-size-h3;
      margin-bottom: 5px;
      padding-bottom: 5px;
    }
    .up-sub-header {
      font-size: $font-size-h6;
    }
    .up-controls {
      text-align: center;
      .value-pair {
        margin-bottom: 1rem;
      }
      .text-right {
        text-align: center!important;
      }
    }
  }




  // TIMED ACTIVITY
  .timed-activities {
    padding: 0;
  }
  .timed-activity {
    display: block;
    font-size: $font-size-base;
    .ta-date {
      flex: 0 0 auto;
      margin-bottom: 3rem;
      text-align: left;
      span:after {
        transform: none;
        left: 0px;
      }
    }
    .ta-record-w {
      padding-left: 0px;
    }
    .ta-record {
      display: block;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid rgba(0,0,0,0.05);
    }
    .ta-activity {
      font-size: $font-size-base * 0.9;
    }
  }




  .content-panel-toggler {
    display: none;
  }
  .menu-mobile {
    display: block;
  }
  .element-info {
    .element-search {
      margin-top: 1rem;
    }
  }
  .element-box .os-tabs-controls, .invoice-w .os-tabs-controls, .big-error-w .os-tabs-controls {
    display: block;
    margin-left: -1rem;
    margin-right: -1rem;
    .nav + .nav {
      margin-top: 1rem;
    }
  }
  .element-box.el-tablo {
    text-align: center;
  }
  .invoice-w .infos {
    display: block;
    .info-2 {
      text-align: left;
      padding-top: 30px;
    }
  }
  .invoice-heading {
    margin-bottom: 2rem;
    margin-top: 4rem;
  }
  .invoice-body {
    display: block;
    .invoice-desc {
      margin-bottom: 2rem;
    }
  }
  .invoice-footer {
    display: block;
    text-align: center;
    .invoice-logo {
      margin-bottom: 1rem;
    }
  }

  .controls-above-table {
    .form-control {
      width: 110px;
      display: inline-block;
    }
    .btn {
      margin-bottom: 0.5rem;
    }
  }
  .layout-w {
    display: block;
  }
  .content-w, .menu-side .content-w {
    display: block;
  }
  .content-i {
    display: block;
    .content-box {
      display: block;
      padding: 15px;
    }
    .content-panel {
      padding: 15px;
      border-left: none;
      display: block;
      width: auto;
    }
  }
  .big-error-w {
    padding: 1rem;
  }
  .element-wrapper {
    padding-bottom: 1.5rem;
  }
  .element-box {
    padding: 1rem;
  }
  canvas {
    max-width: 100%;
  }
  table {
    max-width: 100%;
  }
  .invoice-w {
    padding: 30px;
  }
  .breadcrumb {
    margin-bottom: 1rem;
  }
}