.alert {
  font-weight: $font-weight-light;
  &.alert-warning {
    .alert-heading {
      color: #3E3221;
    }
  }
  &.borderless {
    border: none;
  }
}