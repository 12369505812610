/*

  # -- STYLES NAVIGATION:

  #1. Element Wrapper Styles
  #2. Tablo Element
  #3. Charts & Legends
  #4. Profile Tiles
  #5. Users list element
  #6. Tabs Controls
  #7. Activity Boxes
  #8. Note box
  #9. Steps
  #10. Invoice
  #11. Error Page
  #12. Authorization styles
  #13. Timed Activities Element
  #14. Progress Bar Element
  #15. Balances


*/


/*
 #1. Element Wrapper Styles
*/

.element-wrapper {
    padding-bottom: 4rem;

    &.compact {
        padding-bottom: 2rem;
    }

    .element-info {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .element-info-with-icon {
            margin-bottom: 0px;
        }
    }

    .element-info-with-icon {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;

        .element-info-icon {
            flex: 0 0 70px;
            color: $brand-primary;
            font-size: 30px;
        }

        .element-info-text {

        }

        &.smaller {
            .element-info-icon {
                flex: 0 0 50px;
                font-size: 20px;
            }

            .element-info-text {
                .element-inner-header {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .element-actions {
        float: right;
        position: relative;
        z-index: 2;

        .btn + .btn {
            margin-left: 10px;
        }
    }

    .element-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            background-color: $brand-primary;
            width: 22px;
            height: 6px;
            border-radius: 2px;
            display: block;
            position: absolute;
            bottom: -3px;
            left: 0px;

        }
    }

    .element-inner-header {
        margin-bottom: 0.5rem;
        margin-top: 0px;
        display: block;
    }

    .element-inner-desc {
        color: #999;
        font-weight: $font-weight-light;
        font-size: $font-size-base * 0.9;
        display: block;
    }

    .element-search {
        position: relative;

        &:before {
            @include osfont();
            position: absolute;
            left: 15px;
            top: 48%;
            transform: translateY(-50%);
            font-size: 20px;
            content: "\e92c";
            color: rgba(0, 0, 0, 0.2);
        }

        input {
            border: none;
            box-shadow: none;
            background-color: #f1f1f1;
            border-radius: 30px;
            padding: 10px 15px 10px 50px;
            display: block;
            width: 100%;
            outline: none;

            &::placeholder {
                color: rgba(0, 0, 0, 0.3);
            }
        }
    }
}

.box-style {
    border-radius: $global-border-radius;
    background-color: #fff;
    box-shadow: $element-box-shadow;
}

.element-box {
    padding: 1.5rem 2rem;
    margin-bottom: 1rem;
    @extend .box-style;

    .os-tabs-controls {
        margin-left: -2rem;
        margin-right: -2rem;

        .nav {
            padding-left: 2rem;
            padding-right: 2rem;
        }
    }

    .centered-header {
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        margin-bottom: 1rem;
    }

    .element-box-header {
        color: darken($brand-primary, 5%);
        margin-bottom: 1rem;
    }
}

.element-box-content + .form-header {
    margin-top: 2rem;
}

.element-box + .element-box {
    margin-top: 2rem;
}

.element-box-tp,
.element-box {
    .input-search-w {
        margin-bottom: 1rem;
    }
}


/*
 #2. Tablo Element
*/


.el-tablo {
    padding-right: 5px;

    .label {
        display: block;
        @include text-small-caps();
        @include text-faded();
    }

    .value {
        font-size: $font-size-base * 2.7;
        font-weight: $font-weight-bold;
        font-family: $font-family-serif;
        letter-spacing: -1px;
        line-height: 1.2;
        display: inline-block;
        vertical-align: middle;
    }


    .trending {
        padding: 3px 10px;
        border-radius: 30px;
        display: inline-block;
        font-size: $font-size-base * 0.8;
        vertical-align: middle;
        margin-left: 1rem;

        .os-icon {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 14px;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .trending-up {
        color: #fff;
        background-color: $brand-success;
    }

    .trending-down {
        color: #fff;
        background-color: $brand-danger;
    }

    .trending-default {
        color: #fff;
        background-color: #636c72;
    }

    .trending-up-basic {
        color: $brand-success;
        padding: 0px;
    }

    .trending-down-basic {
        color: $brand-danger;
        padding: 0px;
    }

    &.centered {
        text-align: center;
    }

    &.padded {
        padding: 2rem;
    }

    &.bigger {
        .value {
            font-size: $font-size-base * 3.7;
        }

        .label {
            font-size: $font-size-base * 1;
        }
    }

    &.highlight {
        .value {
            color: $brand-primary;
        }
    }

}

.el-buttons-list {
    .btn {
        margin-bottom: 0.5rem;
    }

    &.full-width {
        .btn {
            display: block;
        }
    }
}

.el-tablo + .el-chart-w {
    margin-top: 1rem;
}


/*
 #3. Charts & Legend
*/

.el-chart-w {
    position: relative;
    text-align: center;

    .inside-donut-chart-label {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        @include text-small-caps();
        z-index: 1;

        strong {
            display: block;
            font-size: $font-size-base * 1.4;
        }

        span {
            display: block;
            @include text-faded();
        }
    }

    canvas {
        position: relative;
        z-index: 2;
    }
}

.el-chart-w + .el-legend {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 1.5rem;
    margin-top: 2rem;
}

.el-legend {
    .legend-value-w {
        margin-bottom: 0.5rem;
    }

    .legend-pin {
        width: 10px;
        height: 10px;
        border-radius: $global-border-radius;
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.7rem;
    }

    .legend-value {
        display: inline-block;
        vertical-align: middle;
    }
}


/*
 #4. Profile Tile
*/

.profile-tile {
    display: flex;
    align-items: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .profile-tile-box {
        width: 110px;
        text-align: center;
        border-radius: 4px;
        padding: 1.5rem 0.5rem 0.5rem;
        background-color: #fff;
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
    }

    .pt-avatar-w {
        display: inline-block;
        border-radius: 50px;
        overflow: hidden;

        img {
            width: 60px;
            height: auto;
        }
    }

    .pt-user-name {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 0.5rem;
        margin-top: 0.5rem;
        @include text-small-caps();
    }

    .profile-tile-meta {
        flex: 1;
        padding-left: 30px;

        ul {
            list-style: none;
            @include text-small-caps();
            @include text-faded();
            margin: 0px;
            padding: 0px;

            li {
                margin-bottom: 0.3rem;
            }

            strong {
                color: $body-color;
                margin-left: 5px;
            }
        }
    }

    .pt-btn {

    }
}


.status-pill {
    width: 12px;
    height: 12px;
    border-radius: 30px;
    background-color: #eee;
    display: inline-block;
    vertical-align: middle;

    &.yellow {
        background-color: #f8bc34;
    }

    &.red {
        background-color: #c21a1a;
    }

    &.green {
        background-color: #71c21a;
    }

    &.smaller {
        width: 8px;
        height: 8px;
    }

    & + span {
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
    }
}


/*
 #5. Users list element
*/

.users-list-w {
    .user-w {
        display: flex;
        padding: 12px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        align-items: center;
        justify-content: space-between;

        .user-avatar-w {
            width: 50px;

            .user-avatar {
                border-radius: 40px;
                overflow: hidden;

                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }

        &.with-status {
            .user-avatar-w {
                position: relative;

                &:before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    border-radius: 10px;
                    box-shadow: 0px 0px 0px 3px #fff;
                }
            }

            &.status-green .user-avatar-w:before {
                background-color: $brand-success;
            }

            &.status-red .user-avatar-w:before {
                background-color: $brand-danger;
            }
        }

        .user-name {
            padding-left: 20px;
            flex: 1;
        }

        .user-title {
            margin-bottom: 4px;
        }

        .user-role {
            @include text-small-caps();
            @include text-faded();
        }

        .user-action {
            width: 50px;
            color: $brand-primary;
            font-size: 18px;
        }
    }
}


/*
 #6. Tabs Controls
*/

.os-tabs-controls {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;

    .nav-pills {
        align-items: center;
        border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    }

    .nav-tabs {
        flex: 1;
    }
}


/*
 #7. Activity Boxes
*/

.activity-boxes-w {
    .activity-box-w {
        display: flex;
        align-items: center;
        padding: 0.8rem 0px;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 3px;
            background-color: darken($content-bg, 15%);
            top: 0px;
            bottom: 0px;
            left: 75px;
            position: absolute;
        }

        &:after {
            content: "";
            display: block;
            width: 20px;
            background-color: darken($content-bg, 15%);
            top: 50%;
            height: 1px;
            left: 80px;
            position: absolute;
            z-index: 1;
        }

        &:first-child:before {
            top: 50%;
        }

        &:last-child:before {
            bottom: 50%;
        }
    }

    .activity-time {
        flex: 0 0 100px;
        @include text-small-caps();
        @include text-faded();
        text-align: right;
        padding-right: 40px;
    }

    .activity-box {
        padding: 0.8rem;
        @extend .box-style;
        display: flex;
        flex: 1;
        position: relative;
        align-items: center;

        &:before {
            position: absolute;
            top: 50%;
            left: -30px;
            content: "";
            width: 12px;
            height: 12px;
            border: 2px solid #60769f;
            background-color: $content-bg;
            border-radius: 20px;
            transform: translateY(-50%);
            z-index: 2;
        }
    }

    .activity-avatar {
        width: 50px;
        border-radius: 50px;
        overflow: hidden;
        margin-right: 1.5rem;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .activity-info {
        flex: 1;
    }

    .activity-role {
        @include text-small-caps();
        @include text-faded();
        margin-bottom: 0.2rem;
    }

    .activity-title {
        @include text-small-caps();
        display: block;
    }
}


/*
 #8. Note box
*/

.note-box {
    padding: 15%;
    background-color: #F8ECC7;
    margin-bottom: 2rem;

    .note-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 1rem;
        margin-bottom: 1rem;
    }

    .note-content {
        font-weight: $font-weight-light;
        color: rgba(0, 0, 0, 0.6);
        font-size: $font-size-base * 0.9;
    }
}


/*
 #9. Steps
*/

.steps-w {

}

.step-contents {
    .step-content {
        padding: 0px 5%;
        display: none;

        &.active {
            display: block;
        }
    }
}

.step-triggers {
    display: flex;
    margin-bottom: 2.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .step-trigger {
        padding-bottom: 1rem;
        flex: 1;
        text-align: center;
        font-size: $font-size-base * 1.2;
        color: rgba(0, 0, 0, 0.3);
        position: relative;
        transition: all 0.4s ease;

        &:focus {
            text-decoration: none;
        }

        &:hover {
            text-decoration: none;
            color: $body-color;
            cursor: pointer;
        }

        &:before {
            position: absolute;
            content: "";
            height: 4px;
            background-color: $brand-primary;
            left: 0px;
            right: 100%;
            bottom: -2px;
            transition: all 0.4s ease;
        }

        &.complete {
            &:before {
                right: 0px;
            }
        }

        &.active {
            color: $body-color;

            &:before {
                right: 0px;
            }
        }
    }
}


/*
 #10. Invoice
*/

.invoice-w {
    font-family: $font-family-alt;
    max-width: 800px;
    @extend .element-box;
    position: relative;
    overflow: hidden;
    padding: 100px;
    padding-bottom: 20px;

    &:before {
        width: 140%;
        height: 450px;
        background-color: #faf9f3;
        position: absolute;
        top: -15%;
        left: -24%;
        transform: rotate(-27deg);
        content: "";
        z-index: 1;
    }

    .infos {
        position: relative;
        z-index: 2;
        display: flex;
        justify-content: space-between;

        .info-1 {
            font-size: $font-size-base * 1.2;

            .invoice-logo-w {

            }

            .company-name {
                font-size: $font-size-base * 2.5;
                margin-bottom: 0.5rem;
                margin-top: 10px;
            }

            .company-address {
            }

            .company-extra {
                font-size: $font-size-base * 0.9;
                color: rgba(0, 0, 0, 0.4);
                margin-top: 1rem;
            }
        }

        .info-2 {
            padding-top: 140px;
            text-align: right;

            .company-name {
                margin-bottom: 1rem;
                font-size: $font-size-base * 1.4;
            }

            .company-address {
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    .terms {
        font-size: $font-size-base * 0.9;
        margin-top: 2.5rem;

        .terms-header {
            font-size: $font-size-base;
            margin-bottom: 10px;
        }

        .terms-content {
            color: rgba(0, 0, 0, 0.4);
        }
    }
}

.invoice-table {
    thead {
        th {
            border-bottom: 2px solid #333;
        }
    }

    tbody {
        tr {
            td {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }

            &:last-child {
                td {
                    padding-bottom: 40px;
                }
            }
        }
    }

    tfoot {
        tr {
            td {
                border-top: 3px solid #333;
                font-size: $font-size-base * 1.4;
            }
        }
    }
}

.invoice-heading {
    margin-bottom: 4rem;
    margin-top: 7rem;
    position: relative;
    z-index: 2;

    h3 {
        margin-bottom: 0px;
    }
}

.invoice-footer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    margin-top: 6rem;
    position: relative;
    z-index: 2;

    .invoice-logo {
        img {
            vertical-align: middle;
            height: 20px;
            width: auto;
            display: inline-block;
        }

        span {
            vertical-align: middle;
            margin-left: 10px;
            display: inline-block;
        }
    }

    .invoice-info {
        span {
            display: inline-block;
        }

        span + span {
            margin-left: 1rem;
            padding-left: 1rem;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
        }
    }
}

.invoice-body {
    display: flex;

    .invoice-desc {
        flex: 0 1 250px;
        font-size: $font-size-base * 1.3;
        z-index: 2;

        .desc-label {
        }

        .desc-value {
            @extend .text-muted;
        }
    }

    .invoice-table {
        position: relative;
        z-index: 2;
    }
}


/*
 #11. Error Page
*/

.big-error-w {
    width: 450px;
    text-align: center;
    @extend .element-box;
    padding: 50px;
    margin: 0px auto;
    margin-top: 100px;

    h1 {
        font-size: $font-size-h1 * 1.9;
        margin-bottom: 0px;
    }

    h5 {
        color: $brand-primary;
        margin-bottom: 3rem;
    }

    h4 {
        margin-bottom: 0px;
    }

    form {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding-top: 1.5rem;
        margin-top: 1.5rem;
    }
}


/*
 #12. Authorization styles
*/


.auth-box-w {
    max-width: 450px;
    margin: 0px auto;
    background-color: #fff;
    border-radius: $global-border-radius;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);

    &.wider {
        max-width: 500px;
    }

    .logged-user-w {
        padding-top: 0px;
        padding-bottom: 10px;
    }

    .logo-w {
        text-align: center;
        padding: 20%;
    }

    .auth-header {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        position: relative;
        z-index: 1;
        padding-left: 80px;

        &:after {
            content: "";
            background-color: $brand-primary;
            width: 32px;
            height: 7px;
            border-radius: 2px;
            display: block;
            position: absolute;
            bottom: -4px;
            left: 80px;
        }
    }

    form {
        padding: 20px 80px;
        padding-bottom: 60px;

        .form-check-inline {
            margin-left: 1rem;
        }

        .form-group {
            position: relative;

            .pre-icon {
                position: absolute;
                top: 35px;
                font-size: 24px;
                color: $brand-primary;
                left: -38px;
            }
        }
    }

    .buttons-w {
        padding-top: 1rem;
        margin-top: 0.5rem;
        border-top: 1px solid rgba(0, 0, 0, 0.05);
    }


    &.centered {
        text-align: center;

        .auth-header {
            padding-left: 0px;

            &:after {
                left: 50%;
                transform: translateX(-50%);
                left: 50%;
            }
        }
    }
}


/*
 #13. Timed Activities Element
*/

.timed-activities {
    margin-bottom: 1rem;
}

.timed-activity {
    display: flex;
    margin-bottom: 2rem;
    font-size: $font-size-base * 1.2;

    .ta-date {
        flex: 0 0 130px;
        @include text-faded();
        position: relative;
        text-align: center;

        span {
            position: relative;
            display: inline-block;

            &:after {
                content: "";
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -30px;
                width: 16px;
                height: 16px;
                border: 3px solid #C76DC8;
                border-radius: 8px;
                background-color: #fff;
            }
        }

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: -20px;
            width: 1px;
            top: 70px;
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .ta-record-w {
        padding-left: 40px;
    }

    .ta-record {
        display: flex;
        margin-bottom: 10px;
        align-items: flex-start;
    }

    .ta-timestamp {
        position: relative;
        padding-right: 40px;
        @include text-faded();
        width: 110px;
        white-space: nowrap;

        strong {
            color: $body-color;
        }

        &:after {
            content: '\e910';
            @include osfont();
            color: rgba(0, 0, 0, 0.3);
            font-size: 10px;
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-40%);
            display: block;
        }
    }

    .ta-activity {
        a {
            display: inline-block;
            padding-bottom: 1px;
            border-bottom: 1px solid $link-color;

            &:hover {
                text-decoration: none;
                color: darken($link-color, 20%);
                border-bottom-color: darken($link-color, 20%);
            }
        }
    }
}

.timed-activities.compact {
    .timed-activity {
        display: block;
        padding-left: 30px;
        position: relative;

        &:before {
            position: absolute;
            width: 1px;
            background-color: rgba(0, 0, 0, 0.1);
            bottom: 0px;
            top: 45px;
            content: "";
            left: 8px;
        }

        .ta-date {
            flex: 0;
            text-align: left;
            margin-bottom: 1.5rem;

            &:before {
                display: none;
            }

            span:after {
                left: -30px;
                top: 50%;
                bottom: auto;
                right: auto;
                transform: translateY(-50%);
            }
        }

        .ta-record-w {
            padding-left: 20px;
        }

        .ta-record {
            display: block;
        }

        .ta-timestamp {
            font-size: $font-size-base * 0.9;
            margin-bottom: 5px;

            &:after {
                right: auto;
                left: -20px;
                font-size: 8px;
            }
        }

        .ta-activity {
            font-size: $font-size-base * 1;
            margin-bottom: 10px;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
}


/*
 #14. Progress Bar Element
*/


.os-progress-bar {
    margin-bottom: 1rem;

    .bar-labels {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        span {
            font-size: $font-size-base * 0.8;
        }
    }

    .bar-label-left {
        span {
            margin-right: 5px;

            &.positive {
                color: #619B2E;
            }

            &.negative {
                color: #D83536;
            }
        }
    }

    .bar-label-right {
        span {
            margin-left: 5px;

            &.info {
                color: #456CF9;
            }
        }
    }

    .bar-level-1,
    .bar-level-2,
    .bar-level-3 {
        border-radius: 12px;
        height: 6px;
    }

    &.blue, &.primary {
        .bar-level-1 {
            background-color: #F2F2F2;
        }

        .bar-level-2 {
            background-color: lighten($brand-primary, 30%);
        }

        .bar-level-3 {
            background-color: $brand-primary;
        }
    }

    &.floated {
        float: right;
        width: 220px;
    }
}


/*
  #15. Balances
*/

.element-balances {
    display: flex;
    padding: 20px 0px;
    justify-content: space-around;

    .balance {
        padding: 10px 20px;

        & + .balance {
            border-left: 1px solid rgba(0, 0, 0, 0.03);
        }
    }

    .balance-title {
        color: $color-text-faded;
        font-size: $font-size-base  * 1.2;
    }

    .balance-value {
        font-size: $font-size-base  * 2.3;
        font-weight: $font-weight-bold;
        color: darken($body-color, 7%);
        white-space: nowrap;

        &.danger {
            color: darken($brand-danger, 20%);
        }

        span {
            display: inline-block;
            vertical-align: middle;
            line-height: 1.1;
        }

        i {
            line-height: 1.1;
        }

        .balance-value-success {
            color: $brand-success;
        }
    }

    span.trending {
        padding: 3px 10px;
        border-radius: 30px;
        display: inline-block;
        font-size: $font-size-base * 1.1;
        vertical-align: middle;
        margin-left: 10px;

        .os-icon {
            margin-left: 5px;
            vertical-align: middle;
            font-size: 14px;
        }

        span {
            display: inline-block;
            vertical-align: middle;
        }
    }

    span.trending-up {
        color: #fff;
        background-color: $brand-success;
    }

    span.trending-down {
        color: #fff;
        background-color: $brand-danger;
    }

    span.trending-up-basic {
        color: $brand-success;
        padding: 0px;
    }

    span.trending-down-basic {
        color: $brand-danger;
        padding: 0px;
    }
}
